import { useContext, useEffect } from 'react';
import './heading.css';
import StrategyComponent from '../strategy/strategy';
import OptionChainTabsComponent from '../tabs/tabs';
import OrderComponent from '../order/order'
import UpstoxConnect from "../upstox/upstox-connect"
import Logout from '../logout/logout';
import SettingsComponent from '../settings/settings';
import AdminApproval from '../admin-approval/admin-approval';
import SubscribeStrategy from '../subscribe-strategy/subscribe-strategy';
import ContextRBAC from "../context/context";
import TraderCan from "../../context/role-based-access/tradercan";
import axiosClient from '../../utils/axios-helper';
import { useNavigate } from "react-router-dom";
import QuantitySettingComponent from '../quantity-setting/quantity-setting';
const timer = 1000 * 1500;


function Dashboard() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   // setInterval(async () => {
  //   //   try {
  //   //     const refreshToken: any = localStorage.getItem('refreshToken');
  //   //     const response: any = await axiosClient.post('/auth/restore-token', { refreshToken: refreshToken });
  //   //     if (!response?.data?.success) {
  //   //       throw new Error();
  //   //     }
  //   //     console.log('===Response from helper', response.data.newaccessToken);
  //   //     localStorage.setItem("accessToken", response.data.newaccessToken);
  //   //   }
  //   //   catch (error) {
  //   //     console.log('Error while fetching access token');
  //   //     navigate("/login");
  //   //   }
  //   // }, timer)
  // }, [])



  const { rbcId }: any = useContext(ContextRBAC);
  return (
    <div className="heading">
      <div className="heading-1">
        <StrategyComponent />
      </div>
      <div className="heading-2">
        <div className='heading-button-component'>
          <UpstoxConnect />
          <SettingsComponent />
          <TraderCan id={rbcId} control="ADD_USER_QUANTITY"><QuantitySettingComponent /></TraderCan>
          <TraderCan id={rbcId} control="TRADER_APPROVAL"><AdminApproval /></TraderCan>
          <TraderCan id={rbcId} control="SUBSCRIBE_STRATEGY"><SubscribeStrategy /></TraderCan>
          <Logout />
        </div>
        <hr />
        <OptionChainTabsComponent />
        <hr />
        <OrderComponent />
      </div>
    </div>

  );
}

export default Dashboard;