import { useState, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { USER_QUANTITY_FIELDS } from "../../utils/constants";
import axiosClient from '../../utils/axios-helper';
import ButtonComponent from '../reusable-components/button';
import ModalComponent from '../reusable-components/modal';

export default function QuantitySettingComponent() {
    const [showModal, setShowModal] = useState(false);
    const [settingsData, setSettingsData]: any = useState({ lotSize: 1 });
    const [instrumentData, setInstrumentData] = useState([]);
    const [settingsDataError, setSettingsDataError]: any = useState("");
    const [updateButton, setUpdateButton]: any = useState(false);

    useEffect(() => {
        async function getUserId() {
            const userDetails: any = localStorage.getItem("user");
            const parsedUserDetails = await JSON.parse(userDetails);
            const userId = parsedUserDetails?.UserId;
            setSettingsData((prevState) => ({
                ...prevState,
                userId
            }))
        }
        getUserId();
    }, [])

    const handleShowSettingsModal = async () => {
        try {
            let instrumentList: any = await axiosClient.get(`/instrument/fetch-all`);
            if (instrumentList?.data?.data) {
                instrumentList = instrumentList?.data?.data;
                setInstrumentData(instrumentList);
            }
            const dataToFetchUserLotSize = { userId: settingsData.userId, indexId: instrumentList[0].indexId };
            const userQuantity = await axiosClient.post("/user/fetch-user-quantity", dataToFetchUserLotSize);
            if (userQuantity?.data?.data && userQuantity?.data?.data.length > 0) {
                const userLotData = userQuantity?.data?.data;
                const existingId = userLotData[0].id;
                setSettingsData({ userId: settingsData?.userId, indexId: instrumentList[0].indexId, lotSize: userLotData[0].lotSize, id: existingId })
            }
            else {
                setSettingsData({ userId: settingsData.userId, indexId: instrumentList[0].indexId, lotSize: 1 })
            }
            setShowModal(true);
        }
        catch (error: any) {
            console.log('Error', error);
        }
    }
    const handleClose = async () => {
        try {
            setShowModal(false);
        }
        catch (error) {
            console.log('Error', error);
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            if (settingsData.id) {
                const { id, indexId, userId, lotSize } = settingsData
                const updateObj = { id, updateObj: { indexId, userId, lotSize } }
                await axiosClient.post("/user/update-user-quantity", updateObj)
            }
            else {
                if (settingsData)
                    await axiosClient.post("/user/add-user-quantity", settingsData)
            }
            setShowModal(false);
        }
        catch (error: any) {
            console.log(error);
            setSettingsDataError(error?.message);
        }
    }

    const handleIndexSelection = async (e: any) => {
        try {
            e.preventDefault();
            setSettingsData((prevState) => ({
                ...prevState,
                indexId: e.target.value
            }));
            const dataToFetchUserLotSize = { userId: settingsData.userId, indexId: e.target.value };
            const userQuantity = await axiosClient.post("/user/fetch-user-quantity", dataToFetchUserLotSize);
            if (userQuantity?.data?.data && userQuantity?.data?.data.length > 0) {
                const userLotData = userQuantity?.data?.data;
                setSettingsData((prevState) => ({
                    ...prevState,
                    lotSize: userLotData[0].lotSize,
                    id: userLotData[0].id
                }))
            }
            else {
                setSettingsData((prevState) => ({
                    ...prevState,
                    id: null,
                    lotSize : 1
                }))
            }
        }
        catch (error) {
            console.log("Error", error);
        }
    }

    const showFormField = (item: any) => {
        return (
            <Row key={item.id} id={item.id}>
                <Col>
                    <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <div className='label-container'>
                            <span><Form.Label>{item.name}</Form.Label></span>
                        </div>
                        <div className="field-container">
                            <Form.Control
                                type="text"
                                onChange={(e) => {
                                    console.log("Target value", settingsData)
                                    setSettingsData({ ...settingsData, lotSize: e.target.value })
                                }}
                                value={settingsData.lotSize}
                                required
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </Col>
            </Row>)
    }

    const showSelectField = (item: any) => {
        return (
            <Row key={item.id} id={item.id}>
                <Col>
                    <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <div className='label-container'>
                            <Form.Label>{item.name}</Form.Label>
                        </div>
                        <div className="field-container">
                            <Form.Select onChange={(e) => handleIndexSelection(e)} value={settingsData[item.apiIdentifier]} aria-label="Default select example">
                                {instrumentData.length > 0 && instrumentData.map((value: any, index: any) => <option key={index} value={value["indexId"]}>{`${value[item.apiIdentifier]} (${value.quantity})`}</option>)}
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        )
    }

    const chooseFieldType = (item: any) => {
        switch (item.fieldType) {
            case "Select":
                return showSelectField(item);
            case "Text":
                return showFormField(item)
        }
    }
    return (
        <div className="upstox-button">
            <ButtonComponent
                variant="info"
                onClick={handleShowSettingsModal}
            >Modify Lot Size
            </ButtonComponent>
            <ModalComponent show={showModal} onHide={handleClose} headerContent={"Create Settings"} bodyContent={USER_QUANTITY_FIELDS.map((item) => (chooseFieldType(item)))} footerCloseButtonName={"Close"} footerSubmitButtonName={updateButton ? "Update" : "Add"}
                onSubmit={(e: any) => handleSubmit(e)} />
        </div>
    )

}