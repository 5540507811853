import { createContext } from 'react';
import AuthWrapper, { AuthContextProvider } from './auth-context';

interface Props{
    children  : React.ReactNode;
}

export const AuthContext = createContext<AuthContextProvider | null>(null);

const AuthProvider: React.FC<Props> = ({children}) => {
    const auth = AuthWrapper();
    return <AuthContext.Provider value={auth}>{children} </AuthContext.Provider>
}

export default AuthProvider;