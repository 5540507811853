import ListGroup from 'react-bootstrap/ListGroup';
import './strategy.css';
import { useEffect, useState } from 'react';
import StrategyModal from '../strategy-modal/strategy-modal';
import axiosClient from '../../utils/axios-helper';
import Badge from 'react-bootstrap/Badge';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import ToggleSwitch from '../toggle-switch/toggle-switch';
import React, { useContext } from "react";
import ContextRBAC from "../context/context";
import TraderCan from "../../context/role-based-access/tradercan";



export default function StrategyComponent() {
    const { rbcId }: any = useContext(ContextRBAC);
    const [showModal, setShowModal] = useState(false);
    const [strategyList, setStrategyList] = useState([]);
    const [strategyDetails, setStrategyDetails]: any = useState();
    const [deleteIcon, setdeleteIcon] = useState(0);
    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');

    const radios = [
        { name: 'Active', value: '1' },
        { name: 'Inactive', value: '2' }
    ];

    useEffect(() => {
        axiosClient.get("/strategy/get")
            .then((response) => {
                setStrategyList(response?.data?.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])

    const handleClose = () => {
        setShowModal(false);
    }
    const handleShow = async (strategyItem: any) => {
        try {
            setStrategyDetails(strategyItem);
            setShowModal(true);
        }
        catch (error) {
            console.log(error);
        }
    }

    const showDeleteIcon = (strategyID: number) => {
        setdeleteIcon(strategyID);
    }

    const handleDelete = async (id: String) => {
        try {
            const deleteObj = {
                "strategyId": id
            }
            const response = await axiosClient.delete("/strategy/disable");
        }
        catch (error) {
            console.log(error);
        }

    }

    // const logState = () => {
    // }


    return (
        <div className="strategy-section">
            {strategyList && strategyList.length > 0 && <ListGroup as="ul">
                <div className='strategy-heading'>
                    <div>Strategies</div>
                    <TraderCan key={rbcId} id={rbcId} control="ADD_STRATEGY">
                        <div className='add-button' onClick={() => handleShow({})}>
                            <span className="material-symbols-outlined">
                                add_circle
                            </span>
                        </div>
                    </TraderCan>



                </div>
                {strategyList.length > 0 && strategyList.map((strategyItem: any) => (
                    <ListGroup.Item action onMouseLeave={() => showDeleteIcon(0)} onMouseEnter={() => showDeleteIcon(strategyItem.strategyId)} id={`${strategyItem.strategyId}`}>
                        <div className='strategy-row-list'>
                            <div onClick={() => handleShow(strategyItem)} className='strategy-detail'>{`${strategyItem.strategyName}`}</div>
                            {/* {
                                strategyItem.strategyId === deleteIcon && <span className="material-symbols-outlined">
                                    more_vert
                                </span>
                            } */}
                            <TraderCan id={rbcId} control="DELETE_STRATEGY">
                                {strategyItem.strategyId === deleteIcon && <Badge bg="primary" onClick={() => handleDelete(strategyItem.strategyId)}>
                                    <span className="material-symbols-outlined" style={{ fontSize: "20px" }}>
                                        delete
                                    </span>
                                </Badge>}
                            </TraderCan>
                        </div>

                        {/* <div>
                            <ToggleSwitch label={`${strategyItem.strategyName}`} id={strategyItem.strategyId} toggled={true} onClick={logState} />
                        </div> */}

                    </ListGroup.Item>
                ))}
            </ListGroup>}
            {showModal && <StrategyModal show={showModal} handleClose={handleClose} strategyDetails={strategyDetails} />}
        </div>
    )
}