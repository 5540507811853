import Offcanvas from 'react-bootstrap/Offcanvas';

function OffCanvas({ name, showCanvas, handleHideCanvas, text }: { name: any, showCanvas: any, handleHideCanvas: any, text : string }) {

    return (
        <>
            <Offcanvas show={showCanvas} onHide={handleHideCanvas} placement={"end"} enforceFocus={false} >
                <Offcanvas.Header>
                    <Offcanvas.Title>Abbreviation</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{"padding" : "40px"}}>
                    {text}
                    <br/>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default OffCanvas;