import Spinner from 'react-bootstrap/Spinner';
import "./spinner.css";


type Iprops = {
    show: boolean
}

function LoadingSpinner({ show }: Iprops) {
    return (
        <div className={show ? "show-spinner" : "hide-spinner"}>
        <Spinner animation="border" role="status" >
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        </div>

    );
}

export default LoadingSpinner;