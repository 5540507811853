export const LABEL_CONSTANTS = [{
    id: 1,
    name: "TT",
    tooltipText: "TT : Transaction Type. For example, Buy or Sell Webhook will receive it in the format such as strategy.order.action",
    apiIdentifier: "strategyType"
},
{
    id: 2,
    name: "TS",
    tooltipText: "TS : Trade Symbol. For example, PUT option of Bank Nifty September 13, 2023 exapiry will appear like BANKNIFTY23913 close PE",
    apiIdentifier: "tradeSymbol"
},
{
    id: 3,
    name: "NEAR",
    tooltipText: "NEAR : means that the option strike price will be round down to the nearest “100”. For example, bank nifty has strike price in the multiples of 100, so if the current market is 44438, the current strike will be considered as 44400.",
    apiIdentifier: "near"
},
{
    id: 4,
    name: "AT",
    tooltipText: "AT : It represents the broker. For example, UPSTOXV2",
    apiIdentifier: "brokerAT"
},
{
    id: 5,
    name: "E",
    tooltipText: "E : Exchange. For NSE Futures and Options, symbol will be NFO",
    apiIdentifier: "exchange"
},
{
    id: 6,
    name: "Q",
    tooltipText: "Q : Quantity. This is the quantity for which order will be placed",
    apiIdentifier: "quantity"
},
{
    id: 7,
    name: "OT",
    tooltipText: "OT : Order Type. It can be “CALL, PUT",
    apiIdentifier: "orderType"
},
{
    id: 8,
    name: "CAL/ATMOTM",
    tooltipText: "CAL : This signifies the way we are going to calculate the strike price that we are going to buy. For example, bank nifty has strike price in the multiples of 100, so if the current market is 44438, the current strike will be considered as 44400. And if we are buying a PUT option, we would want to take 100 or 200 points above the current price and if we want to buy CALL option, we would want to buy 100 or 200 points below the current strike price hence a CALL Buy calculation would look like CAL: -200 and a PUT Buy calculation would look like: CAL: +200",
    apiIdentifier: "atmotm"
},
{
    id: 9,
    name: "P",
    tooltipText: "P : It signifies if the order was either Intraday, Delivery, etc.",
    apiIdentifier: "product"
},
{
    id: 10,
    name: "VL",
    tooltipText: "VL : Stands for Validity. It can be one of the following - DAY(default), “IOC.” We will be using the default value, i.e., DAY",
    apiIdentifier: "validity"
},
{
    id: 11,
    name: "WT",
    tooltipText: " WT : Stands for waiting Time. After placing a Buy order if it is not executed within the set time, the pending order shall be cancelled. Here is where we set the waiting time for order cancellation.",
    apiIdentifier: "waitingTime"
},
{
    id: 12,
    name: "TSL",
    tooltipText: "TSL : Trailing stop loss. If a trade is ongoing, we would set a maximum draw down (ie., certain percentage of the gain already achieved) so that we would exit the trade with profit in case the market reverses.",
    apiIdentifier: "trailingStopLoss"
},
{
    id: 13,
    name: "Entry Price",
    tooltipText: "EP : Entry Price. After we receive a signal from the strategy in the web hook, we will check the option chain for the current price of the symbol and place a buy order in the following way: LTP (Last Traded Price) - [set value]. For example, LTP-4, means 4 Rupees lower than the last traded price. If the last traded price (LTP) is 344, LTP-4 means we would by the symbol at 340Rupees. ",
    apiIdentifier: "entryPrice"
},
{
    id: 14,
    name: "SL",
    tooltipText: "SL : Stop Loss. We will exit a trade with loss if it goes lesser than certain amount from the entry price.",
    apiIdentifier: "stopLoss"
},
{
    id: 15,
    name: "TSL TRIGGER",
    tooltipText: "TSLT : Trailing Stop loss trigger",
    apiIdentifier: "tslTrigger"
},
{
    id: 16,
    name: "MP",
    tooltipText: "MP : Minimum profit to be booked once the profit once market reverses its trend",
    apiIdentifier: "minimumProfitToBeBooked"
},
{
    id: 17,
    name: "Name",
    tooltipText: "Name : Give a unique name for Strategy",
    apiIdentifier: "strategyName"
},
{
    id: 18,
    name: "Exit Price",
    tooltipText: "Exit Price. After we receive a signal from the strategy in the web hook, we will check the option chain for the current price of the symbol and place a buy order in the following way: LTP (Last Traded Price) - [set value]. For example, LTP-4, means 4 Rupees lower than the last traded price. If the last traded price (LTP) is 344, LTP-4 means we would by the symbol at 340Rupees. ",
    apiIdentifier: "exitPrice"
},
]

export const SELECT_VALUES = {
    ORDER_TYPE: [
        {
            id: 1,
            value: "CALL"
        },
        {
            id: 2,
            value: "PUT"
        }
    ],
    PRODUCT: [{
        id: 1,
        displayValue: "INTRADAY",
        value: "I"
    },
    {
        id: 2,
        displayValue: "DELIVERY",
        value: "D"
    }],
    TRADE_SYMBOL: [{
        id: 1,
        value: "BANKNIFTY"
    },
    {
        id: 2,
        value: "NIFTY"
    },
    {
        id: 3,
        value: "SENSEX"
    }
    ],
    VALIDITY: [{
        id: 1,
        value: "DAY"
    },
    {
        id: 2,
        value: "IOC"
    }

    ]
}

export const SETTINGS_FIELDS = [{
    id: "1",
    name: "Broker Name",
    apiIdentifier: "Broker",
    dropDownValues: ["Upstox", "Zerodha"]
},
{
    id: "2",
    name: "Enter Quantity",
    apiIdentifier: "Quantity"
},
{
    id: "3",
    name: "Upstox API Key",
    apiIdentifier: "ApiKey"
},
{
    id: "4",
    name: "Upstox API Secret",
    apiIdentifier: "ApiSecret"
}
];

export const USER_QUANTITY_FIELDS = [{
    id: "1",
    name: "Select Index",
    apiIdentifier: "indexName",
    fieldType : "Select"
}, {
    id: "2",
    name: "Enter Lot Size",
    apiIdentifier: "lotSize",
    fieldType : "Text"
}
]