import WebeltekLogo from "../../assets/logo.jpeg";


const logoStyle = {
    "background-color": "white",
    "padding": "20px",
    "height": "35%",
    "box-sizing": "border-box"
}

const WebeltekLogoComponent = () => {
    return (
            <img style={{
                backgroundColor : "white",
                "padding": "20px",
                "height": "25%",
                boxSizing: "border-box",
                backgroundImage: "url('../../assets/logo.jpeg')"
            }} src={WebeltekLogo} alt="Logo Image" />
    )
};

export default WebeltekLogoComponent;
