import { useEffect, useState, useContext } from 'react';
import ButtonComponent from "../reusable-components/button";
// import Button from 'react-bootstrap/Button'
import "./upstox-connect.css";
import axiosClient from '../../utils/axios-helper';
import ContextRBAC from "../context/context";
import TraderCan from "../../context/role-based-access/tradercan";


function UpstoxConnect() {
  const { rbcId }: any = useContext(ContextRBAC);
  // const [isLoading, setLoading] = useState(false);
  const [enableUpstoxButton, setEnableUpstoxButton] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const upstoxStatus = urlParams && urlParams.get('status');
    if (upstoxStatus) {
      localStorage.setItem('upstoxStatus', upstoxStatus);
      setEnableUpstoxButton(false);
    }
  }, []);

  const handleClick = async (e: any) => {
    try {
      e.preventDefault();
      const res: any = await axiosClient.get("/auth/upstox/v2");
      const userDetails: any = localStorage.getItem("user");
      const parsedUserDetails = await JSON.parse(userDetails);
      const userID = parsedUserDetails?.UserId;
      window.location.replace(`${res.data.url}?id=${userID}`)
    }
    catch (err) {
      console.log('Error', err);
      return;
    }
    // window.open(`https://api-v2.upstox.com/login/authorization/dialog?response_type=code&client_id=b5fa470c-00dc-4fab-9623-d8e480a3cc16&redirect_uri=http://127.0.0.1:3000`, "_self")
  }

  return (
    <TraderCan id={rbcId} control="CONNECT_UPSTOX">
      <div className="upstox-button">
        <ButtonComponent
          variant="primary"
          disabled={!enableUpstoxButton}
          onClick={(e : any) => handleClick(e)}
        >
          {enableUpstoxButton ? 'Connect to Upstox' : 'Connected to Upstox'}
        </ButtonComponent>
      </div>
    </TraderCan>

  );
}

export default UpstoxConnect;