import { Button } from 'react-bootstrap';

const ButtonComponent = ({
    variant = 'primary',
    onClick,
    children,
    className = '',
    disabled = false,
    type
}: { type?: any, variant: string, onClick?: any, children: string, className?: string, disabled?: boolean }
) => {
    return (
        <Button className={className} variant={variant} onClick={onClick} disabled={disabled} type={type}>
            {children}
        </Button>

    )
}

export default ButtonComponent;
