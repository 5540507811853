import { useState, useContext, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { SETTINGS_FIELDS } from "../../utils/constants";
import axiosClient from '../../utils/axios-helper';
import ContextRBAC from "../context/context";
import TraderCan from "../../context/role-based-access/tradercan";
import AlertComponent from '../alert/alert';
import ButtonComponent from '../reusable-components/button';
import ModalComponent from '../reusable-components/modal';


export default function SettingsComponent() {
    const { rbcId }: any = useContext(ContextRBAC);
    const [showModal, setShowModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [settingsData, setSettingsData]: any = useState({ Broker: "UPSTOX", Quantity: 0, ApiKey: "", ApiSecret: "" });
    const [settingsDataError, setSettingsDataError]: any = useState("");
    const [updateButton, setUpdateButton]: any = useState(false);


    const handleShowSettingsModal = async () => {
        try {
            setShowModal(true);
            const settingsDataFromAPI = await axiosClient.get(`/user/get-settings`);
            if (settingsDataFromAPI?.data?.data) {
                const extractedSettingsData = settingsDataFromAPI?.data?.data;
                setUpdateButton(true);
                const { UserId, Quantity, ApiKey, ApiSecret, Broker } = extractedSettingsData;
                setSettingsData({ UserId, Quantity, ApiKey, ApiSecret, Broker });
            }

        }
        catch (error: any) {
            console.log('Error', error);
            setSettingsDataError(error?.message);
        }
    }

    const handleClose = async () => {
        try {
            setShowModal(false);
        }
        catch (error) {
            console.log('Error', error);
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            const userDetails: any = localStorage.getItem("user");
            const parsedUserDetails = await JSON.parse(userDetails);
            const userID = parsedUserDetails?.UserId;
            settingsData.UserId = userID;
            settingsData.Quantity = Number(settingsData.Quantity);
            if (updateButton) {
                await axiosClient.post("/user/update-settings", settingsData);
            }
            else {
                await axiosClient.post("/user/create-settings", settingsData);
            }
            setShowModal(false);
        }
        catch (error: any) {
            console.log(error);
            setSettingsDataError(error?.message);
        }
    }

    const showFormField = (item: any) => {
        return (
            <Row key={item.id} id={item.id}>
                <Col>
                    <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <div className='label-container'>
                            <span><Form.Label>{item.name}</Form.Label></span>
                        </div>
                        <div className="field-container">
                            <Form.Control
                                type={item.id === "2" ? "number" : "text"}
                                onChange={(e) => {
                                    setSettingsData({ ...settingsData, [item.apiIdentifier]: e.target.value })
                                }}
                                value={settingsData[item.apiIdentifier]}
                                required
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </Col>
            </Row>)
    }

    const showSelectField = (item: any) => {
        return (
            <Row key={item.id} id={item.id}>
                <Col>
                    <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <div className='label-container'>
                            <Form.Label>{item.name}</Form.Label>
                        </div>
                        <div className="field-container">
                            <Form.Select onChange={(e) => {
                                setSettingsData({ ...settingsData, [item.apiIdentifier]: e.target.value })
                            }} value={settingsData[item.apiIdentifier]} aria-label="Default select example">
                                {item.dropDownValues.map((value: any, index: any) => <option key={index} value={value}>{value}</option>)}
                            </Form.Select>

                        </div>
                    </Form.Group>
                </Col>
            </Row>
        )
    }

    const chooseFieldType = (item: any) => {
        if (item.id === "1")
            return showSelectField(item);
        else
            return showFormField(item);
    }

    return (
        <>
            <TraderCan id={rbcId} control="ADD_SETTINGS">
                <div className="upstox-button">
                    <ButtonComponent
                        variant="info"
                        onClick={handleShowSettingsModal}
                    >Add / Update Settings
                    </ButtonComponent>
                    <ModalComponent show={showModal} onHide={handleClose} headerContent={"Create Settings"} bodyContent={SETTINGS_FIELDS.map((item) => (chooseFieldType(item)))} footerCloseButtonName={"Close"} footerSubmitButtonName={updateButton ? "Update" : "Add"}
                        onSubmit={(e: any) => handleSubmit(e)} />
                </div>
            </TraderCan>
        </>

    );
}
