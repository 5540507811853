import axios from "axios";
import config from "./config";
import { useNavigate } from "react-router-dom";
// const navigate = useNavigate();

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: { ...config.commonHeaders }
});


axiosClient.interceptors.request.use((request) => {
    let accessToken: any = localStorage.getItem('accessToken');
    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
},
    (error) => {
        console.log('Error', error);
        return Promise.reject(error);
    }
);


axiosClient.interceptors.response.use(response => response, async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry && originalRequest?.url !== "/auth/login") {
        originalRequest._retry = true;

        try {
            const refreshToken: any = localStorage.getItem('refreshToken'); 
            const response: any = await axiosClient.post('/auth/restore-token', { refreshToken: refreshToken });
            if (!response?.data?.success) {
                throw new Error();
            }
            console.log('===Response from helper', response.data.newaccessToken);
            localStorage.setItem("accessToken", response.data.newaccessToken);
            return new Promise((resolve, reject) => {
                axios.request(originalRequest).then(response => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                })
            });

        } catch (e) {
            localStorage.setItem("user", "");
            localStorage.setItem("accessToken", "")
            localStorage.setItem("refreshToken", "")
            localStorage.setItem("upstoxAuthCode", "");
            return Promise.reject(e);
        }
    }

    return Promise.reject(error);
})



export default axiosClient;