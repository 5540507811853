import { useState, useEffect, useReducer } from "react";
import { registerUserApi, termsAndConditionsApi } from "../api";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';

const useRegister = () => {
    const navigate = useNavigate();
    const [error, setError] = useState({ message: "" });
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [termsText, setTermsText] = useState("");
    const [formData, setFormData] = useReducer((formData: any, newItem: any) => { return ({ ...formData, ...newItem }) }, { UserName: "", Password: "", PhoneNumber: "", Email: "", UserType: "Trader", AcceptedTermsAndConditions: false })
    const [checked, setChecked] = useState(0);

    const handleHideAlert = () => {
        setShowSuccessAlert(false);
        window.location.reload();
    }

    const handleOpenLoginPage = () => {
        navigate("/login")
    }

    const handleRegister = async (e) => {
        e.preventDefault();
        await registerUser(formData, checked);
    }

    const registerUser = async (formData, checked) => {
        try {
            const { UserName, Password, UserType, PhoneNumber, Email } = formData
            if (UserName === "" || UserName.length < 3) {
                setError({ message: "Please enter a valid username with 3 characters" })
            }

            else if (!Password || (Password === "" || Password.length < 6)) {
                setError({ message: "Please enter a valid password" })
            }

            else if (!PhoneNumber || (PhoneNumber === "" || PhoneNumber.length < 10)) {
                setError({ message: "Please enter a valid phone number" })
            }
            else if (!checked) {
                setError({ message: "Please accept the terms and conditions" })
            }
            else {
                setLoading(true);
                formData.UserType = "Trader";
                formData.AcceptedTermsAndConditions = true;
                await registerUserApi(formData);
                setShowSuccessAlert(true);
                setFormData({ UserName: "", Password: "", PhoneNumber: "", Email: "" });
                setChecked(0);
            }
        }
        catch (err) {
            console.log("Error", err);
            setError(err)
        }
        finally {
            setLoading(false);
        }
    }

    const handleTermsConditions = async () => {
        try {
            let termsConditionsUrl: any = await termsAndConditionsApi();
            if (termsConditionsUrl?.termsText) {
                const termsConditionParsedHtmlText: any = parse(termsConditionsUrl?.termsText);
                setTermsText(termsConditionParsedHtmlText);
                setShowTermsModal(true);
            }
        }
        catch (err) {
            console.log("Error", err);
            setError(err);
        }
    }

    const handleTermsModal = () => {
        setShowTermsModal((prevState) => !prevState);
    }

    const handleChange = (key?: any, value?: any) => {
        setFormData({ [key]: value });
    }

    const handleChecked = (e) => {
        setChecked(e.target.checked);
    }


    return {
        loading,
        error,
        showSuccessAlert,
        registerUser,
        handleHideAlert,
        handleOpenLoginPage,
        handleTermsConditions,
        handleTermsModal,
        showTermsModal,
        termsText,
        handleRegister,
        formData,
        checked,
        handleChange,
        handleChecked
    }
}

export default useRegister;

