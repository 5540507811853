import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import "./order.css";
import { useState, useEffect } from 'react';
import axiosClient from '../../utils/axios-helper';
import { Button, Tab } from 'react-bootstrap';
import ModalComponent from '../reusable-components/modal';

export default function OrderComponent() {
    const [orders, setOrders] = useState([]);
    const [key, setKey] = useState('home');
    const [openModal, setOpenModal] = useState(false);
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                let orderDetails: any = await axiosClient.post("/order/fetch-orders", { orderStatusType: "Ongoing", orderTransactionType: "BUY" });
                if (orderDetails?.data?.data) {
                    orderDetails = orderDetails?.data?.data;
                    setOrders(orderDetails);
                }
            }
            catch (error) {
                console.log("Error", error);
            }
        }
        fetchOrders();
    }, [])

    const handleSell = async (strategyID) => {
        try {
            await axiosClient.post("/webhook/tradingview", [{ TT: "sell", StrategyID: strategyID }]);
            window.location.reload();
        }
        catch (error) {
            console.log("Error", error);
        }
    }

    const handleSubmit = async () => {
        setOpenModal(true);
    }

    const handleClose = () => {
        setOpenModal(false)
    }


    return (
        <div className="order-section">
            <Tabs
                title="option-chain"
                id="controlled-tab-example"
                className="mb-3"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="home" title="Ongoing">
                    <Table striped bordered hover variant="light">
                        <thead>
                            <tr>
                                <th>StrategyID</th>
                                <th>Strike Price</th>
                                <th>Transaction Type</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map(order => (
                                <tr>
                                    <td>{order.StrategyId}</td>
                                    <td>{order.strike_price}</td>
                                    <td>{order.transaction_type}</td>
                                    <td>{order.createdAt}</td>
                                    <td><Button onClick={() => handleSubmit()}>Click To Sell</Button></td>
                                    <ModalComponent show={openModal} onHide={handleClose} headerContent={"Confirm to sell order"} footerCloseButtonName={"Cancel"} footerSubmitButtonName={"Confirm"} bodyContent={`Would you like to sell the order with strike price of ${order.strike_price}?`} onSubmit={() => handleSell(order.StrategyId)} />
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Tab>
            </Tabs>
        </div>
    )
}
