import axiosClient from '../utils/axios-helper';

export const registerUserApi = async (data) => {
    try {
        const response = await axiosClient.post("/auth/register", data);
        return response?.data?.data;
    }
    catch (error) {
        throw error;
    }
}

export const termsAndConditionsApi = async () => {
    try{
        let response: any = await axiosClient.get("/terms-conditions/fetch-new");
        response = response?.data?.data;
        return response;
    }
    catch (error) {
        throw error;
    }
}