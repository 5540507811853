import { useEffect, useState, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import "./strategy-modal.css";
import OffCanvas from '../offcanvas/offcanvas';
import { LABEL_CONSTANTS, SELECT_VALUES } from "../../utils/constants";
import axiosClient from '../../utils/axios-helper';
import ContextRBAC from "../context/context";
import TraderCan from "../../context/role-based-access/tradercan";
import ButtonComponent from '../reusable-components/button';

type IProps = {
    show: boolean,
    handleClose: () => void,
    strategyDetails: { strategyId: number }
}



export default function StrategyModal({ show, handleClose, strategyDetails }: IProps) {
    const { rbcId }: any = useContext(ContextRBAC);
    const [showCanvas, setShowCanvas] = useState(false);
    const [offCanvasText, setOffCanvasText] = useState("");
    const [showTooltip, setShowTooltip] = useState({ id: 1 })
    const [newStrategy, setNewStrategy] = useState(false);
    const [strategyTempData, setStrategyTempData]: [strategyTempData: any, setStrategyTempData: any] = useState({ orderType: SELECT_VALUES.ORDER_TYPE[0].value, product: SELECT_VALUES.PRODUCT[0].value, tradeSymbol: SELECT_VALUES.TRADE_SYMBOL[0].value, validity: SELECT_VALUES.VALIDITY[0].value });
    const [strategyApiData, setStrategyApiData]: [strategyApiData: any, setStrategyApiData: any] = useState({});
    // const [modalError, setModalError] = useState({});
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (strategyDetails?.strategyId) {
            axiosClient.get(`/strategy/get/${strategyDetails.strategyId}`)
                .then((response) => {
                    setStrategyTempData(response?.data?.data)
                    setStrategyApiData(response?.data?.data)
                })
                .catch(error => {
                    console.error('Error', error);
                })
        }
        else {
            setNewStrategy(true);
        }

    }, [strategyDetails])


    const handleCanvasShow = (text: string) => {
        setShowCanvas(prevState => !prevState);
        setOffCanvasText(text)
    }
    const handleCanvasHide = () => {
        setShowCanvas(false);
        setOffCanvasText("");
    }

    const handleShowTooltip = (id: number) => {
        setShowTooltip({ id });
    }


    const handleUpdate = async () => {
        try {
            await axiosClient.put('/strategy/update', strategyTempData);
            handleClose();
        }
        catch (error) {
            console.error(error);
        }

    }

    const handleAdd = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            const form: any = event.currentTarget;
            if (form?.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            setValidated(true);
            if (strategyTempData?.waitingTime === "" || strategyTempData?.waitingTime?.trim().length === 0) {
                delete strategyTempData.waitingTime;
            }
            await axiosClient.post('/strategy/create', strategyTempData);
            window.location.reload();
            handleClose();
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            if (newStrategy) {
                console.log('=ADDDDD', strategyTempData);
                handleAdd(event);
            }
            else {
                handleUpdate();
            }

        }
        catch (error) {
            // console.error(error);
        }
    }

    const preventMinus = (e: any) => {
        if (e.code === "Minus") {
            e.preventDefault();
        }
    }


    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop={false}>
                <Form noValidate validated={validated} onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title><span>{newStrategy ? "New Strategy" : strategyTempData.strategyName}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* {LABEL_CONSTANTS.map(({ id, name, tooltipText, apiIdentifier }, index) => {
                        return handleRowsAndColumns({ id, name, tooltipText, apiIdentifier, index })
                    })} */}
                        <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[16].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[16].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[16].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[16].id)}
                                            type="text"
                                            onChange={(e) => {
                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[16].apiIdentifier]: e.target.value })
                                            }}
                                            value={strategyTempData[LABEL_CONSTANTS[16].apiIdentifier]}
                                            required
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[0].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[0].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[0].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[0].id)}
                                            type="number"
                                            onChange={(e) => {
                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[0].apiIdentifier]: e.target.value })
                                            }}
                                            value={strategyTempData[LABEL_CONSTANTS[0].apiIdentifier]}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {`Please provide a valid ${LABEL_CONSTANTS[0].name} value`}
                                        </Form.Control.Feedback>
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[1].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[1].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[1].tooltipText)}>
                                            help
                                        </span>}
                                    </div>


                                    <div className="field-container">
                                        <Form.Select value={strategyTempData[LABEL_CONSTANTS[1].apiIdentifier || ""]} onChange={(e) => {
                                            setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[1].apiIdentifier]: e.target.value })
                                        }} onFocus={() => handleShowTooltip(LABEL_CONSTANTS[1].id)} aria-label="Default select example">
                                            {SELECT_VALUES.TRADE_SYMBOL.map(({ id, value }) => <option value={value}>{value}</option>)}
                                        </Form.Select>

                                        {/* <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[1].id)}
                                            required
                                            type="text"
                                            value={strategyTempData[LABEL_CONSTANTS[1].apiIdentifier]}
                                            onChange={(e) => {
                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[1].apiIdentifier]: e.target.value })
                                            }}

                                        /> */}
                                        <Form.Control.Feedback type="invalid">
                                            {`Please provide a valid ${LABEL_CONSTANTS[1].name} value`}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[2].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[2].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[2].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[2].id)}
                                            type="number"
                                            min="0"
                                            value={strategyTempData[LABEL_CONSTANTS[2].apiIdentifier]}
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[2].apiIdentifier]: e.target.value })
                                            }}
                                            onKeyDown={preventMinus}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {`Please provide a valid ${LABEL_CONSTANTS[2].name} value`}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[3].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[3].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[3].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[3].id)}
                                            type="text"
                                            value={strategyTempData[LABEL_CONSTANTS[3].apiIdentifier]}
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[3].apiIdentifier]: e.target.value })
                                            }}
                                            onKeyDown={preventMinus}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {`Please provide a valid ${LABEL_CONSTANTS[3].name} value`}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[4].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[4].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[4].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[4].id)}
                                            type="text"
                                            value={strategyTempData[LABEL_CONSTANTS[4].apiIdentifier]}
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[4].apiIdentifier]: e.target.value })
                                            }}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {`Please provide a valid ${LABEL_CONSTANTS[4].name} value`}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[5].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[5].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[5].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[5].id)}
                                            type="number"
                                            value={strategyTempData[LABEL_CONSTANTS[5].apiIdentifier]}
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[5].apiIdentifier]: e.target.value })
                                            }}
                                            min="0"
                                            onKeyDown={preventMinus}
                                            required
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[6].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[6].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[6].tooltipText)}>
                                            help
                                        </span>}
                                    </div>
                                    <Form.Select value={strategyTempData[LABEL_CONSTANTS[6].apiIdentifier || ""]} onChange={(e) => {
                                        setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[6].apiIdentifier]: e.target.value, [LABEL_CONSTANTS[0].apiIdentifier]: e.target.value })
                                    }} onFocus={() => handleShowTooltip(LABEL_CONSTANTS[6].id)} aria-label="Default select example">
                                        {SELECT_VALUES.ORDER_TYPE.map(({ id, value }) => <option value={value}>{value}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[7].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[7].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[7].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[7].id)}
                                            type="number"
                                            value={strategyTempData[LABEL_CONSTANTS[7].apiIdentifier]}
                                            required
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[7].apiIdentifier]: e.target.value })
                                            }}
                                            min="0"
                                            onKeyDown={preventMinus}

                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[8].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[8].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[8].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Select onChange={(e) => {
                                            setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[8].apiIdentifier]: e.target.value })
                                        }} value={strategyTempData[LABEL_CONSTANTS[8].apiIdentifier]} onFocus={() => handleShowTooltip(LABEL_CONSTANTS[8].id)} aria-label="Default select example">
                                            {SELECT_VALUES.PRODUCT.map(({ value, displayValue }) => <option value={value}>{displayValue}</option>)}
                                        </Form.Select>

                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[9].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[9].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[9].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <Form.Select value={strategyTempData[LABEL_CONSTANTS[9].apiIdentifier || ""]} onChange={(e) => {
                                        setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[9].apiIdentifier]: e.target.value })
                                    }} onFocus={() => handleShowTooltip(LABEL_CONSTANTS[9].id)} aria-label="Default select example">
                                        {SELECT_VALUES.VALIDITY.map(({ id, value }) => <option value={value}>{value}</option>)}
                                    </Form.Select>

                                    {/* <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[9].id)}
                                            type="text"
                                            value={strategyTempData[LABEL_CONSTANTS[9].apiIdentifier]}
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[9].apiIdentifier]: e.target.value })
                                            }}
                                            required
                                        />
                                    </div> */}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[10].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[10].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[10].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[10].id)}
                                            type="text"
                                            value={strategyTempData[LABEL_CONSTANTS[10].apiIdentifier]}
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[10].apiIdentifier]: e.target.value })
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[11].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[11].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[11].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[11].id)}
                                            type="number"
                                            value={strategyTempData[LABEL_CONSTANTS[11].apiIdentifier]}
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[11].apiIdentifier]: e.target.value })
                                            }}
                                            onKeyDown={preventMinus}
                                            min="0"
                                            required

                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[12].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[12].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[12].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[12].id)}
                                            type="number"
                                            value={strategyTempData[LABEL_CONSTANTS[12].apiIdentifier]}
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[12].apiIdentifier]: e.target.value })
                                            }}
                                            onKeyDown={preventMinus}
                                            min="0"
                                            required
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[13].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[13].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[13].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[13].id)}
                                            type="number"
                                            value={strategyTempData[LABEL_CONSTANTS[13].apiIdentifier]}
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[13].apiIdentifier]: e.target.value })
                                            }}
                                            onKeyDown={preventMinus}
                                            min="0"
                                            required
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[17].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[17].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[14].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[17].id)}
                                            type="number"
                                            value={strategyTempData[LABEL_CONSTANTS[17].apiIdentifier]}
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[17].apiIdentifier]: e.target.value })
                                            }}
                                            onKeyDown={preventMinus}
                                            min="0"
                                            required
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            {/* <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[14].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[14].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[14].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[14].id)}
                                            type="number"
                                            value={strategyTempData[LABEL_CONSTANTS[14].apiIdentifier]}
                                            onChange={(e) => {

                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[14].apiIdentifier]: e.target.value })
                                            }}
                                            required
                                        />
                                    </div>
                                </Form.Group>
                            </Col> */}
                        </Row>
                        {/* <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                    <div className='label-container'>
                                        <span><Form.Label>{LABEL_CONSTANTS[15].name}</Form.Label></span>
                                        {showTooltip.id === LABEL_CONSTANTS[15].id && <span className="material-symbols-outlined" onClick={() => handleCanvasShow(LABEL_CONSTANTS[15].tooltipText)}>
                                            help
                                        </span>}
                                    </div>

                                    <div className="field-container">
                                        <Form.Control onFocus={() => handleShowTooltip(LABEL_CONSTANTS[15].id)}
                                            type="number"
                                            value={strategyTempData[LABEL_CONSTANTS[15].apiIdentifier]}
                                            onChange={(e) => {
                                                setStrategyTempData({ ...strategyTempData, [LABEL_CONSTANTS[15].apiIdentifier]: e.target.value })
                                            }}
                                            required
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row> */}
                        {/* <div>{modalError?.message}</div> */}
                    </Modal.Body>

                    <Modal.Footer>
                        <ButtonComponent variant="secondary" onClick={handleClose}>
                            Close
                        </ButtonComponent>
                        <TraderCan id={rbcId} control="EDIT_STRATEGY">
                            <ButtonComponent variant="primary" type="submit">
                                {newStrategy ? "Add" : "Update"}
                            </ButtonComponent>
                        </TraderCan>

                    </Modal.Footer>
                </Form>
            </Modal >
            <OffCanvas name={"end"} showCanvas={showCanvas} handleHideCanvas={handleCanvasHide} text={offCanvasText} />
        </>
    );
}