import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OptionChainComponent from '../option-chain/option-chain';
import ChartViewComponent from '../chart-view/chart-view';
import UtilitiesComponent from '../utilities/utilities';
import "./tabs.css";
import { useEffect } from 'react';

export default function OptionChainTabsComponent() {
    return (
        <div className="tab-section">
            <Tabs
                defaultActiveKey="option-chain"
                id="uncontrolled-tab-example"
                className="mb-3"
                fill
            >
                <Tab eventKey="option-chain" title="Option Chain">
                    <OptionChainComponent />
                </Tab>
                <Tab eventKey="chart-view" title="Chart View">
                    <ChartViewComponent />
                </Tab>
                <Tab eventKey="utilities" title="Utilities">
                    <UtilitiesComponent />
                </Tab>
            </Tabs>
        </div>
    )
}
