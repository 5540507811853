import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import "./login.css";
import { useContext, useEffect, useReducer, useState } from 'react';
import { AuthWrapper } from '../auth/auth-context';
import { useNavigate } from "react-router-dom";
import useAuth from '../auth/useAuth';
import axiosClient from '../../utils/axios-helper';
import LoadingSpinner from "../spinner/spinner";
import ContextRBAC from '../context/context';
import ButtonComponent from '../reusable-components/button';
import WebeltekLogoComponent from '../logo/logo';
// import rbac from "../../context/role-based-access/role-based-access";

function LoginPage() {
    const { switchRole }: any = useContext(ContextRBAC);
    const auth = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState({ message: "" });
    const [formData, setFormData] = useReducer((formData: any, newItem: any) => { return ({ ...formData, ...newItem }) }, { username: "", password: "" })
    const [openRegisterPage, setOpenRegisterPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [userType, setUserType] = useState("");

    useEffect(() => {
        const auth = localStorage.getItem("user");
        if (auth && auth !== "") {
            navigate("/")
        }
    })

    const handleLogin = async () => {
        setIsLoading(true);
        const body = {
            phoneNumber: formData.username,
            password: formData.password
        }
        if (body?.phoneNumber === "" || body?.phoneNumber?.length < 3) {

            setError({ message: "Please enter a valid username" })
        }

        else if (body?.password === "" || body?.password?.length < 6) {
            setError({ message: "Please enter a valid password" })
        }

        else {
            try {
                const response = await axiosClient.post("/auth/login", body);
                const userData = response?.data?.data;
                switchRole(userData?.UserType);
                const { accessToken, refreshToken } = response?.data;
                localStorage.setItem('user', JSON.stringify(userData));
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                setTimeout(() => {
                    setIsLoading(false);
                    navigate("/")
                }, 1000);
            }
            catch (error: any) {
                const errorMessage = error?.response?.data?.message;
                setError({ message: errorMessage })
            }


        }
        setIsLoading(false);
    }

    const handlepOpenRegisterPage = () => {
        navigate("/register")
    }



    return (
        <>
            <div className="login-page">
            <WebeltekLogoComponent />
                <Form>
                    <Form.Group as={Row} className="mb-4" controlId="formPlaintextEmail">
                        <Col sm="10">
                            <Form.Control type="username" size='lg' placeholder="Phone Number" value={formData.username} onChange={(e: any) => setFormData({ username: e.target.value })} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formPlaintextPassword">
                        <Col sm="10">
                            <Form.Control size='lg' type="password" placeholder="Password" value={formData.password} onChange={(e: any) => setFormData({ password: e.target.value })} />
                        </Col>
                    </Form.Group>
                    <div className="error-message">
                        {error?.message}
                    </div>
                    <div className='button-group'>
                        <ButtonComponent onClick={handleLogin} variant="primary" disabled={isLoading}>Login</ButtonComponent>{' '}
                        <ButtonComponent onClick={handlepOpenRegisterPage} variant="link" disabled={isLoading}>Click here to Register</ButtonComponent>
                    </div>
                </Form>
                <LoadingSpinner show={isLoading} />
            </div>
        </>



    );
}

export default LoginPage;