import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { useEffect } from 'react';
import './App.css';
import Dashboard from "./components/heading/heading";
import LoginPage from './components/login/login';
import RegisterPage from './components/register/register';
import PrivateRoutes from './components/auth/private-route';
import { useEffect, useState } from 'react';
import rbac from "./context/role-based-access/role-based-access";
import ContextRBAC from "../src/components/context/context";

rbac.addUserRoles("trader", ["TRADER"]);
rbac.addUserRoles("admin", ["ADMIN"]);
rbac.addUserRoles("Trader", ["TRADER"]);
rbac.addUserRoles("Admin", ["ADMIN"]);


function App() {

  const switchRole = (currentRole: any) => {
    setRoleValue((prevRole) => ({ rbac: prevRole.rbac, rbcId: currentRole, switchRole }));
  }

  const [roleValue, setRoleValue] = useState({
    rbac,
    rbcId: "admin",
    switchRole
  })

  useEffect(() => {
    const userDetails: any = localStorage.getItem("user");
    const parsedUser = userDetails && JSON.parse(userDetails);
    if (parsedUser?.UserType) {
      switchRole(parsedUser?.UserType)
    }
  }, [])


  return (
    <ContextRBAC.Provider
      value={roleValue}
    >
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<Dashboard />} path="/" />
            </Route>
            <Route element={<LoginPage />} path="/login" />
            <Route element={<RegisterPage />} path="/register" />
          </Routes>
        </BrowserRouter>

      </div>
    </ContextRBAC.Provider>

  );
}

export default App;