import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import "./register.css";
import { Button } from 'react-bootstrap';
import ModalComponent from '../reusable-components/modal';
import LoadingSpinner from '../spinner/spinner';
import useRegister from '../../containers/useRegister';
import WebeltekLogo from "../../assets/logo.jpeg";
import WebeltekLogoComponent from '../logo/logo';


export default function Register() {
    const {
        loading,
        error,
        showSuccessAlert,
        handleHideAlert,
        handleOpenLoginPage,
        handleTermsConditions,
        handleTermsModal,
        showTermsModal,
        termsText,
        formData,
        handleChange,
        handleRegister,
        handleChecked
    } = useRegister();


    return (
        <div className="register-page">
            <WebeltekLogoComponent />
            <Form>
                <Form.Group as={Row} className="mb-4" controlId="formPlaintextName">
                    <Col sm="10">
                        <Form.Control type="username" size='lg' placeholder="Username" value={formData.Username} onChange={(e: any) => handleChange("UserName", e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-4" controlId="formPlaintextPassword">
                    <Col sm="10">
                        <Form.Control size='lg' type="password" placeholder="Password" value={formData.Password} onChange={(e: any) => handleChange("Password", e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-4" controlId="formPlaintextMobileNumber">
                    <Col sm="10">
                        <Form.Control type="mobile-number" size='lg' placeholder="Mobile Number" value={formData.PhoneNumber} onChange={(e: any) => handleChange("PhoneNumber", e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-4" controlId="formPlaintextEmail">
                    <Col sm="10">
                        <Form.Control type="email" size='lg' placeholder="Email Address" value={formData.Email} onChange={(e: any) => handleChange("Email", e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group>
                    <Form.Check type={"checkbox"}>
                        <Form.Check.Input
                            type={"checkbox"}
                            onChange={handleChecked}
                        />
                        <Form.Check.Label>{"I accept the "}<span className='terms-conditions-link' onClick={handleTermsConditions}>{"terms and conditions"} </span></Form.Check.Label>
                    </Form.Check>
                </Form.Group>
                <br />
                <div className="error-message">
                    {error?.message}
                </div>
                <br />

                <div className='button-group'>
                    <Button onClick={handleRegister} variant="primary">Register</Button>{' '}
                    <Button onClick={handleOpenLoginPage} variant="link">Click here to Login</Button>
                </div>
                <ModalComponent onHide={handleTermsModal} show={showTermsModal} headerContent={"WEBELTEK CAPITAL - TERMS AND CONDITIONS"} bodyContent={termsText} hideFooter={true} size="lg" scrollable={true}
                />
                <ModalComponent show={showSuccessAlert} headerContent={'Success'} bodyContent={'Registration Successful. Please wait for our support team to verify and approve your request.'} hideFooter={true} hideHeaderIcon={false} headerIcon={"check_circle"} centered={true} onHide={handleHideAlert} />
                <LoadingSpinner show={loading} />
            </Form>
        </div>
    )
}
