import rbac from "rbacjs";
const rolesConfig : any = [
    {
        roles: ["TRADER"],
        permissions: ["READ", "CONNECT_UPSTOX", "ADD_SETTINGS","ADD_USER_QUANTITY"]
    },
    {
        roles: ["ADMIN"],
        permissions: ["READ", "EDIT_STRATEGY", "DELETE_STRATEGY", "ADD_STRATEGY","TRADER_APPROVAL", "SUBSCRIBE_STRATEGY", "SELL_ORDER"]
    }
];
export default new rbac({ rolesConfig });
