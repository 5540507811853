import { useEffect, useState, useContext } from 'react';
import { Modal, Col, Row, Form, ListGroup } from 'react-bootstrap';
import axiosClient from '../../utils/axios-helper';
import ContextRBAC from "../context/context";
import TraderCan from "../../context/role-based-access/tradercan";
import Table from 'react-bootstrap/Table';
import "./subscribe-strategy.css";
import ButtonComponent from '../reusable-components/button';


function SubscribeStrategy() {
    const { rbcId }: any = useContext(ContextRBAC);
    const [showModal, setShowModal] = useState(false);
    // const [isLoading, setLoading] = useState(false);
    const [enableUpstoxButton, setEnableUpstoxButton] = useState(true);
    const [traderDetails, setTraderDetails] = useState([]);
    const [subscribeList, setSubscribeList] = useState([]);
    const [currentUserId, setCurrentUserId] = useState(0);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const upstoxStatus = urlParams.get('status');
        if (upstoxStatus) {
            localStorage.setItem('upstoxStatus', upstoxStatus);
            setEnableUpstoxButton(false);
        }
        return () => {

        }
    }, [traderDetails, subscribeList, currentUserId]);

    const handleClick = async (e: any) => {
        try {
            setShowModal(true);
            let tradersList: any = await axiosClient.get(`/admin/traders`)
            tradersList = tradersList?.data?.data;
            setTraderDetails(tradersList);
        }
        catch (err) {
            console.log('error in get new traders list', err);
        }
    }

    const handleClose = () => {
        setShowModal(false);
        setTraderDetails([]);
        setSubscribeList([]);
        setCurrentUserId(0);
    }

    const handleSubscribeOrUnsubscribe = async (strategyId: any) => {
        try {
            const body = { userId: currentUserId, strategyId }
            await axiosClient.post(`/admin/subscribe-strategy`, body);
            handleClose();
        }
        catch (err) {
            console.log('error in get new traders list', err);
        }
    }

    const listUserStrategies = async (event?: any) => {
        try {
            const userId = event.target.value;
            let subscribeList: any = await axiosClient.get(`/admin/user-strategy/${userId}`)
            subscribeList = subscribeList?.data?.data;
            setCurrentUserId(userId);
            setSubscribeList(subscribeList);
        }
        catch (err) {
            console.log('error in get new traders list', err);
        }
    }

    return (
        <>
            <TraderCan id={rbcId} control="SUBSCRIBE_STRATEGY">
                <div className="upstox-button">
                    <ButtonComponent
                        variant="primary"
                        onClick={(e : any) => handleClick(e)}
                    >
                        {"Subscribe Users to Strategy"}
                    </ButtonComponent>
                </div>
            </TraderCan>
            <Modal show={showModal} backdrop={false} onHide={handleClose} >
                <Form >
                    <Modal.Header closeButton>
                        <Modal.Title className="subscribe-modal-title">Subscribe Traders to Strategy</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label className='subscribe-strategy-label'>Please select a trader to subscribe to strategy</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={listUserStrategies}>
                            <option>Select one</option>
                            {traderDetails.map((trader: any) =>
                                <option value={trader.UserId}>{trader.UserName}</option>
                            )}
                        </Form.Select>
                        <Form>
                            <Form.Label className='subscribe-strategy-label'>
                                Please select a strategy to subscribe or Unsubscribe
                            </Form.Label>
                            {subscribeList && subscribeList.length > 0 && subscribeList.map((subscriber: any) => (
                                <Form.Check
                                    onChange={() => handleSubscribeOrUnsubscribe(subscriber.strategyId)}
                                    type="switch"
                                    id="custom-switch"
                                    label={subscriber.strategyId}
                                    checked={subscriber.status}
                                />
                            ))}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonComponent variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </ButtonComponent>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>

    );
}

export default SubscribeStrategy;