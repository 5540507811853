import { useEffect, useState, useContext } from 'react';
import { Modal, Col, Row, Form, ListGroup } from 'react-bootstrap';
import axiosClient from '../../utils/axios-helper';
import ContextRBAC from "../context/context";
import TraderCan from "../../context/role-based-access/tradercan";
import "./admin-approval.css";
import ButtonComponent from '../reusable-components/button';


function AdminApproval() {
    const { rbcId }: any = useContext(ContextRBAC);
    const [showModal, setShowModal] = useState(false);
    // const [isLoading, setLoading] = useState(false);
    const [enableUpstoxButton, setEnableUpstoxButton] = useState(true);
    const [traderDetails, setTraderDetails] = useState([]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const upstoxStatus = urlParams.get('status');
        if (upstoxStatus) {
            localStorage.setItem('upstoxStatus', upstoxStatus);
            setEnableUpstoxButton(false);
        }
    }, []);

    const handleClick = async () => {
        try {
            setShowModal(true);
            let newTradersList: any = await axiosClient.get(`/admin/new-traders`)
            newTradersList = newTradersList?.data?.data;
            setTraderDetails(newTradersList);
        }
        catch (err) {
            console.log('error in get new traders list', err);
        }
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const handleAcceptRejectTrader = async (type: string, UserId: any) => {
        try {
            switch (type) {
                case "ACCEPT":
                    await axiosClient.post("/admin/accept-reject", { UserId, UpdateObj: { IsVerified: 1 } });
                    return;

                case "REJECT":
                    await axiosClient.post("/admin/accept-reject", { UserId, UpdateObj: { IsVerified: -1 } });
                    return;
            }

        }
        catch (err) {
            console.log('error in get new traders list', err);
        }
    }

    const handleUpdateTradersList = async (type: string, UserId: any) => {
        await handleAcceptRejectTrader(type, UserId);
        const updatedTraders = traderDetails.filter((trader: any) => {
            return trader.UserId !== UserId
        })
        setTraderDetails(updatedTraders)
    }
    return (
        <>
            <TraderCan id={rbcId} control="TRADER_APPROVAL">
                <div className="upstox-button">
                    <ButtonComponent
                        variant="primary"
                        onClick={handleClick}
                    >
                        {"Approve / Reject Traders"}
                    </ButtonComponent>
                </div>
            </TraderCan>
            <Modal show={showModal} backdrop={false} onHide={handleClose} >
                <Form >
                    <Modal.Header closeButton>
                        <Modal.Title>Approve or Reject Traders</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{
                        <ListGroup as="ol" numbered>
                            {(traderDetails && traderDetails.length > 0 ? traderDetails.map(({ UserName, PhoneNumber, UserId }) => (
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-star"
                                >
                                    <div className="ms-3">
                                        <div className="fw-bold">{UserName}</div>
                                        {PhoneNumber}
                                    </div>
                                    <div className='buttons-list'>
                                        <ButtonComponent variant="success" onClick={() => handleUpdateTradersList("ACCEPT", UserId)}>Yes</ButtonComponent>
                                        <ButtonComponent variant="danger" onClick={() => handleUpdateTradersList("REJECT", UserId)}>No</ButtonComponent>
                                    </div>

                                </ListGroup.Item>
                            )) : <div>No users to display</div>)}
                        </ListGroup>
                    }</Modal.Body>
                    <Modal.Footer>
                        <ButtonComponent variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </ButtonComponent>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>

    );
}

export default AdminApproval;