import { Modal } from 'react-bootstrap';
import ButtonComponent from './button';

const ModalComponent = ({size = 'md', scrollable = false, show, onHide, headerContent, bodyContent, footerCloseButtonName, footerSubmitButtonName, onSubmit, hideFooter, hideHeaderIcon, headerIcon, centered }: any) => {
    return (
        <Modal show={show} onHide={onHide} backdrop={false} centered={centered} size={size} scrollable={scrollable}>
            <Modal.Header closeButton>
                <Modal.Title>{headerContent}</Modal.Title>
                <span hidden={hideHeaderIcon} className="material-symbols-outlined">
                    {headerIcon}
                </span>
            </Modal.Header>
            <Modal.Body>
                {bodyContent}
            </Modal.Body>
            <Modal.Footer hidden={hideFooter}>
                <ButtonComponent variant="secondary" onClick={onHide}>
                    {footerCloseButtonName}
                </ButtonComponent>
                <ButtonComponent variant="primary" onClick={onSubmit}>
                    {footerSubmitButtonName}
                </ButtonComponent>
            </Modal.Footer>
            {/* </Form> */}
        </Modal>

    )
}

export default ModalComponent;
