import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useEffect, useState } from 'react';

const dropDownItems = [
    {
        "id": 1,
        "name": "Bank Nifty",
        "action": "#/action-1",
        "active": true
    },
    {
        "id": 2,
        "name": "Nifty",
        "action": "#/action-2"
    },
    {
        "id": 3,
        "name": "Fin Nifty",
        "action": "#/action-3"
    }
]

// const optionDetails = [
//     {
//         id: 1,
//         parent_id : 1,
//         symbol: "Bank Nifty - 44000",
//         lastTradedPrice: 440,
//         currentPrice: 440,
//         spotPrice: 44000,
//         optionType: "CE",
//         expiryDate: "25 Jun 2020",
//         underLyingSymbol: "BANK NIFTY",
//     },
//     {
//         id: 2,
//         parent_id : 1,
//         symbol: "Bank Nifty - 44000",
//         lastTradedPrice: 440,
//         currentPrice: 440,
//         spotPrice: 44000,
//         optionType: "PE",
//         expiryDate: "25 Jun 2020",
//         underLyingSymbol: "BANK NIFTY",

//     },
//     {
//         id: 3,
//         parent_id : 2,
//         symbol: "Nifty- 44000",
//         lastTradedPrice: 440,
//         currentPrice: 440,
//         spotPrice: 44000,
//         optionType: "PE",
//         expiryDate: "25 Jun 2020",
//         underLyingSymbol: "BANK NIFTY",

//     },
//     {
//         id: 4,
//         parent_id : 2,
//         symbol: "Nifty - 44000",
//         lastTradedPrice: 440,
//         currentPrice: 440,
//         spotPrice: 44000,
//         optionType: "PE",
//         expiryDate: "25 Jun 2020",
//         underLyingSymbol: "BANK NIFTY",

//     },
//     {
//         id: 5,
//         parent_id : 3,
//         symbol: "Fin Nifty - 44000",
//         lastTradedPrice: 440,
//         currentPrice: 440,
//         spotPrice: 44000,
//         optionType: "PE",
//         expiryDate: "25 Jun 2020",

//     },
//     {
//         id: 6,
//         parent_id : 3,
//         symbol: "Fin Nifty - 44000",
//         lastTradedPrice: 440,
//         currentPrice: 440,
//         spotPrice: 44000,
//         optionType: "PE",
//         expiryDate: "25 Jun 2020",

//     }
// ]

// const renderRowData = (item : any, index : number) => {
//     if(index === 0){
//         return <td colSpan={2}>{item.symbol}</td>
//     }
//     else{
//         <td>{item.lastTradedPrice}</td>   
//     }
// }


export default function OptionChainComponent() {
    // const [optionChainData, setOptionChainData]: [optionChainData: any, setOptionChainData: any] = useState([]);
    // useEffect(() => { 
    //     websocket =       
    //     const message = {
    //         MessageType: "Authenticate",
    //         Password: process.env.REACT_APP_GLOBAL_DATA_FEED_API_KEY
    //     }
    //     const newSocket = io('ws://test.lisuns.com:4575/Authenticate');
    //     newSocket.on('message', (message: any) => {
    //         setOptionChainData([...optionChainData, message])
    //     })
    //     // se
    //     console.log('optionChainData', optionChainData);
    // })



    const [optionData, setOptionData] = useState([

        {
            id: 1,
            value: 440.50,
        },
        {
            id: 2,
            value: 442.25,
        },
        {
            id: 3,
            value: 300.30,
        },
        {
            id: 4,
            value: 302.45,
        }

    ]);

    const [currentOptionIndex, setCurrentOptionIndex] = useState("Bank Nifty");

    useEffect(() => {
        const newOptionData: any = optionData.map(({ id, value }) => {
            value += 0.75;
            return { id, value }
        })
        setTimeout(() => setOptionData(newOptionData), 2000);
    }, [])

    const handleDropDownChange = (e: any) => {
        console.log('---E', e)
        setCurrentOptionIndex(e);
    }

    return (
        <Table striped="columns" bordered hover >
            <thead>
                <tr>
                    <th colSpan={2}>
                        Symbol
                    </th>
                    <th colSpan={2}>CALL</th>
                    <th colSpan={2}>PUT</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={2}>
                        <DropdownButton id="dropdown-basic-button" title={`${currentOptionIndex}`} onSelect={handleDropDownChange}>
                            {dropDownItems.map((item, index) => (<Dropdown.Item key={item.id} eventKey={item.name} active={item.active}>
                                {item.name}
                            </Dropdown.Item>))}
                        </DropdownButton>
                    </td>
                    <td>LTP</td>
                    <td>Price</td>
                    <td>LTP</td>
                    <td>Price</td>
                </tr>
                {optionData.map(({ id, value }) => (
                    <tr key={id}>
                        <td colSpan={2}>{currentOptionIndex} - 44000</td>
                        <td>{value}</td>
                        <td>{value}</td>
                        <td>{value}</td>
                        <td>{value}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}
