import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ButtonComponent from '../reusable-components/button';

function Logout() {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        function simulateNetworkRequest() {
            return new Promise((resolve) => setTimeout(resolve, 2000));
        }

        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });
        }
    }, [isLoading]);

    const handleLogout = () => {
        localStorage.setItem("user", "");
        localStorage.setItem("accessToken", "")
        localStorage.setItem("refreshToken", "")
        localStorage.setItem("upstoxAuthCode", "");
        navigate("/login");
    }

    return (
        <div className="upstox-button">
            <ButtonComponent
                variant="secondary"
                onClick={handleLogout}
            >Logout
            </ButtonComponent>
        </div>
    );
}

export default Logout;